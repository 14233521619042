export const isValidArray = (arr) => {
	return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj) => {
	return obj && Object.keys(obj).length > 0;
};

export const isServer = () => {
	const devUrl = process.env.REACT_APP_URL_DEV;
	const prodUrl = process.env.REACT_APP_URL_PROD;
	return process.env.NODE_ENV === 'development' ? devUrl : prodUrl;
};

export const getToken = () => {
	return localStorage.getItem('token');
};

export const setIds = (key, id) => {
	const localId = sessionStorage.getItem(key);

	if (localId) {
		const isIn = localId.split(',').includes(id);
		if (!isIn) {
			let link = localId + ',' + id;
			sessionStorage.setItem(key, link);
			return { update: true };
		}
		const val = localId
			.split(',')
			.filter((i) => i !== id)
			.join(',');
		sessionStorage.setItem(key, val);
		return { update: true };
	}
	sessionStorage.setItem(key, id);
	return { update: true };
};
