import { onFailureReducer, onSuccessReducer } from "redux/store/util";
import { USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS } from "../constants/birk";

const intialState = {
  data: [],
  error: false,
  isLoading: true,
};

export default (state = intialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return onSuccessReducer(state, action);
    case USER_LOGIN_FAILURE:
      return onFailureReducer(state, action);
    default:
      return { ...state };
  }
};

