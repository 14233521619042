import { CUSTOMER_ADMIN_SUCCESS, CUSTOMER_ADMIN_FAILURE } from "../../constants/birk";

const initStat = { data: [], error: false, errorData: "", isLoading: true };



export default (state = initStat, action) => {
    switch (action.type) {
      case CUSTOMER_ADMIN_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case CUSTOMER_ADMIN_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
  };