import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				{/* <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} /> */}
				<Route
					exact
					path={`${APP_PREFIX_PATH}/checkout`}
					component={lazy(() => import(`./folia`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/success`}
					component={lazy(() => import(`./success`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/error`}
					component={lazy(() => import(`./error`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/failure`}
					component={lazy(() => import(`./failure`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/order-list`}
					component={lazy(() => import(`./orderList`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/admin/dashboard`}
					component={lazy(() => import(`./dashboard/index`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/admin/orderlist`}
					component={lazy(() => import(`./orderList/index`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/admin/customerlist`}
					component={lazy(() => import(`./customers/customers`))}
				/>
				<Route
					exact
					path={`${APP_PREFIX_PATH}/order/summary`}
					component={lazy(() => import(`./payment/index`))}
				/>

				<Redirect from={`${APP_PREFIX_PATH}`} to={`/auth/login`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
