export const onSuccessReducer = (state, action) => {
  return {
    ...state,
    error: false,
    data: action.payload,
    isLoading: false,
  };
};

export const onFailureReducer = (state, action) => {
  return {
    ...state,
    error: true,
    data: action.payload,
    isLoading: false,
  };
};
