export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

//Appartment vendor
export const VENDOR_SUCCESS = 'VENDOR_SUCCESS';
export const VENDOR_FAILURE = 'VENDOR_FAILURE';

//Property vendor
export const PROPERTY_SUCCESS = 'PROPERTY_SUCCESS';
export const PROPERTY_FAILURE = 'PROPERTY_FAILURE';

//Order List
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';

//Order Details List
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

//Package List
export const PACKAGE_LIST_SUCCESS = 'PACKAGE_LIST_SUCCESS';
export const PACKAGE_LIST_FAILURE = 'PACKAGE_LIST_FAILURE';

//motorisation List
export const MOTORISATION_LIST_SUCCESS = 'MOTORISATION_LIST_SUCCESS';
export const MOTORISATION_LIST_FAILURE = 'MOTORISATION_LIST_FAILURE';

//ADMIN Order list
export const ORDER_ADMIN_SUCCESS = 'ORDER_ADMIN_SUCCESS';
export const ORDER_ADMIN_FAILURE = 'ORDER_ADMIN_FAILURE';

//ADMIN customer list
export const CUSTOMER_ADMIN_SUCCESS = 'CUSTOMER_ADMIN_SUCCESS';
export const CUSTOMER_ADMIN_FAILURE = 'CUSTOMER_ADMIN_FAILURE';

//ADMIN login list
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

//ADMIN changePassword
export const ADMIN_CHANGE_PASSWORD_SUCCESS = 'ADMIN_CHANGE_PASSWORD_SUCCESS';
export const ADMIN_CHANGE_PASSWORD_FAILURE = 'ADMIN_CHANGE_PASSWORD_FAILURE';

// post payment details
export const POST_PAYMENT_DETAILS_SUCCESS = 'POST_PAYMENT_DETAILS_SUCCESS';
export const POST_PAYMENT_DETAILS_FAILURE = 'POST_PAYMENT_DETAILS_FAILURE';

// get payment details
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE';

//put personal detail
export const PERSONAL_DETAILS_SUCCESS = 'PERSONAL_DETAILS_SUCCESS';
export const PERSONAL_DETAILS_FAILURE = 'PERSONAL_DETAILS_FAILURE';

// post payment success data
export const POST_SUCCESS_PAYMENT_DATA_SUCCESS =
	'POST_SUCCESS_PAYMENT_DATA_SUCCESS';
export const POST_SUCCESS_PAYMENT_DATA_FAILURE =
	'POST_SUCCESS_PAYMENT_DATA_FAILURE';

//package details by id
export const PACKAGE_BY_ID_SUCCESS = 'PACKAGE_BY_ID_SUCCESS';
export const PACKAGE_BY_ID_FAILURE = 'PACKAGE_BY_ID_FAILURE';

//country code details
export const COUNTRY_CODE_SUCCESS = 'COUNTRY_CODE_SUCCESS';
export const COUNTRY_CODE_FAILURE = 'COUNTRY_CODE_FAILURE';
