import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Login from './login.reducer';
import Vendor from './vendor.reducer';
import Property from './property.reducer';
import OrderList from './orderList.reducer';
import orderListDetail from './orderListDetail.reducer';
import PackageList from './packageList.reducer';
import MotorisationList from './motorisationList.reducer';
import AdminOrderList from './admin/orderList.reducer';
import AdminCustomerList from './admin/customer.reducer';
import AdminLogin from './admin/adminLogin.reducer';
import adminChangePass from './admin/adminChangePassword.reducer';
import PostPaymentDetails from './postPaymentDetails.reducer';
import GetOrderById from './getPaymentDetails.reducer';
import PutPersonalDetails from './personalDetail.reducer';
import GetPackageById from './getPackageById.reducer';
import CountryCode from './countryCode.reducer';

const reducers = combineReducers({
	theme: Theme,
	auth: Auth,
	getUserLogin: Login,
	getVendor: Vendor,
	getProperty: Property,
	getOrderList: OrderList,
	getOrderListDetail: orderListDetail,
	getPackageList: PackageList,
	getMotorisationList: MotorisationList,
	getAdminOrderList: AdminOrderList,
	getAdminCustomerList: AdminCustomerList,
	getAdminLogin: AdminLogin,
	getadminChangePass: adminChangePass,
	paymentDetailsPost: PostPaymentDetails,
	getOrderById: GetOrderById,
	putPersonalDetail: PutPersonalDetails,
	getPackageById: GetPackageById,
	countryCode: CountryCode,
});

export default reducers;
