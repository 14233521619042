import { PACKAGE_LIST_SUCCESS, PACKAGE_LIST_FAILURE } from "../constants/birk";

const initStat = { data: [], error: false, errorData: "", isLoading: true };



export default (state = initStat, action) => {
    switch (action.type) {
      case PACKAGE_LIST_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case PACKAGE_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          errorData: action.payload,
          error: true,
        };
      default:
        return { ...state };
    }
  };