import { onFailureReducer, onSuccessReducer } from "redux/store/util";
import {
  POST_PAYMENT_DETAILS_SUCCESS,
  POST_PAYMENT_DETAILS_FAILURE,
} from "../constants/birk";

const intialState = {
  data: [],
  error: false,
  isLoading: true,
};

export default (state = intialState, action) => {
  switch (action.type) {
    case POST_PAYMENT_DETAILS_SUCCESS:
      return onSuccessReducer(state, action);
    case POST_PAYMENT_DETAILS_FAILURE:
      return onFailureReducer(state, action);
    default:
      return { ...state };
  }
};
